import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiBase, imageBase, iconBase } from "../utils/constant";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import 'react-slideshow-image/dist/styles.css';
import Swiper from "swiper";
import CustomCarousel from "./customslider";
import BedroomParentIcon from '@mui/icons-material/BedroomParent';
import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined';
import AccessibleIcon from '@mui/icons-material/Accessible';
import BedroomChildOutlinedIcon from '@mui/icons-material/BedroomChildOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, } from "@mui/material";
import Grid from "@mui/material/Grid";
import CloseIcon from '@mui/icons-material/Close';
import prImg from "../images/pr-image.png";
import iconBedroom from "../images/icon-bedroom.svg";
import iconBathroom from "../images/icon-bathroom.svg";
import iconPurpletick from "../images/purple-tick.svg";
import iconWheelchair from "../images/icon-wheelchair.svg";
import iconHighphysicalsupport from "../images/icon-handshake.svg";
// import MDButton from "components/MDButton";

function Page3() {

  const search = useLocation().search;
  let history = useHistory();
  let imageUrl = ""
  // let propertydata = [];
  const [propertydata, setList] = useState([]);
  const [wheelChair, setWheelChair] = useState("");
  const [stretcher, setStretcher] = useState("");
  const [propertySDAData, setPropertySDAData] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [aboutProperty, setAboutProperty] = useState('');
  const id = new URLSearchParams(search).get("id");

  useEffect(() => {
    getpropertyDetailsById();
  }, []);

  const getpropertyDetailsById = () => {
    // var url = window.location.href
    // var propertyUrl = url.split("?id=");
    // var propertyID = propertyUrl[1];

    var pData = {
      id: id,
    };

    axios
      .post(apiBase + "/Property/getPropertylistById", pData)
      .then((res) => {

        console.log(res);

        if (res.data.success) {
          var addressLine1 = ""
          if (res.data.data[0][0].AddrLine1 != "") {
            addressLine1 = res.data.data[0][0].AddrLine1 + ", "
          }

          var addressLine2 = ""
          if (res.data.data[0][0].AddrLine2 != "") {
            addressLine2 = res.data.data[0][0].AddrLine2 + ", "
          }

          var addressLine3 = ""
          if (res.data.data[0][0].AddrLine3 != "") {
            addressLine3 = res.data.data[0][0].AddrLine3 + ", "
          }
          var Address =
            addressLine1 +
            addressLine2 +
            addressLine3 +
            res.data.data[0][0].City +
            ", " + res.data.data[0][0].State + ", " +
            res.data.data[0][0].PostalCode +
            ", Australia";
          var imageHtml = "";
          var imagesdata = res.data.data[0][0].URL;

          let propertydata = []
          const propertyImages = imagesdata.split(",");
          for (let i = 0; i < propertyImages.length; i++) {
            propertydata.push({
              imgSrc: "https://servicesapi.valuecare.org.au/" + propertyImages[i]
            });
            imageUrl = "https://servicesapi.valuecare.org.au/" + propertyImages[0]
          }
          console.log("propertydataAkshit", propertydata);
          // document.getElementById("setImage").innerHTML = "<img class = 'imgwidth' src = '" +imageUrl+ "' />";

          setList(propertydata)

          var AccessibilityHTML = "";
          console.log("Res dfg = ", res.data.data[0][0].PropertyAccesibility);
          var Accessibilitydata = res.data.data[0][0].PropertyAccesibility;


          if (Accessibilitydata.includes(",")) {
            var splitAccibility = Accessibilitydata.split(",");

            for (let j = 0; j < splitAccibility.length; j++) {
              if (splitAccibility[j] === "WheelChair") {
                setWheelChair('WheelChair');
              }
              if (splitAccibility[j] === "Stratcher") {
                setStretcher('Stretcher');
              }
            }
          } else {
            if (Accessibilitydata === "WheelChair") {
              setWheelChair('WheelChair');
            }
            if (Accessibilitydata === "Stratcher") {
              setStretcher('Stretcher');
            }
          }

          var Property_SDAHTML = "";
          var SDAdata = res.data.data[0][0].PropertySDA;

          if (SDAdata.includes(",")) {
            var splitSDA = SDAdata.split(",");

            for (let j = 0; j < splitSDA.length; j++) {
              Property_SDAHTML +=
                '<li><img src="' +
                iconBase +
                '/wp-content/themes/valueCare/images/check-white.svg" alt="Valuecare"><span>' +
                splitSDA[j] +
                "</span></li>";
            }
          } else {
            if (SDAdata === "High Physical Support") {
              setPropertySDAData('High Physical Support');
            }
          }

          document.getElementById("Address").innerHTML = Address;
          // document.getElementById("imageSlider").innerHTML = imageHtml;
          console.log("about  = ", res.data.data[0][0].AboutProperty);

          document.getElementById("Bedroom").innerHTML =
            res.data.data[0][0].TotalBedroomCount;
          document.getElementById("Bathroom").innerHTML =
            res.data.data[0][0].BathroomCount;
          // document.getElementById("AvlBedroom").innerHTML =
          //   res.data.data[0][0].TotalAvailableBedroomCount;
          document.getElementById("AvlBedrooms").innerHTML =
            res.data.data[0][0].TotalAvailableBedroomCount;

          // document.getElementById("Accessibility").innerHTML =
            // AccessibilityHTML;
          // document.getElementById("AboutProperty").innerHTML =
          //   res.data.data[0][0].AboutProperty;

          setAboutProperty(res.data.data[0][0].AboutProperty);  

          document.getElementById("ValueCarePropertyId").innerHTML =
            res.data.data[0][0].ValueCarePropertyId;

          // document.getElementById("SDAProperty").innerHTML = Property_SDAHTML;

          var propType = ""

          if (res.data.data[0][0].PropertyType == "SDA") {
            // propType = "Specialist Disability Accommodation"
            propType = "Specialised Disability Accommodation"
          }
          else if (res.data.data[0][0].PropertyType == "SIL") {
            propType = "Supported Independent Living"
          }
          else {
            propType = res.data.data[0][0].PropertyType
          }

          document.getElementById("propertyType").innerHTML =
            propType + " Care";

          console.log(Address);
        }
      })
      .catch((err) => {
        console.log("setLock err", err);
      });
  };

  const removeError = (id) => {
    document.getElementById(id).innerHTML = ""
  }

  const scrolldiv = () => {
    var elem = document.getElementById("inspectionForm");
    elem.scrollIntoView();
}

  const buttonClick = () => {
    console.log("URL = ", id);
    let AreYou = [];
    const checkboxes = document.getElementsByName("areyoua");
    checkboxes.forEach((el) => el.checked && AreYou.push(el.value));
    var AreYouAString = "";
    for (var i = 0; i < AreYou.length; i++) {
      AreYouAString += AreYou[i] + "-";
    }

    AreYouAString = AreYouAString.substring(0, AreYouAString.length - 1);

    // let About = [];
    // const checkboxesAbout = document.getElementsByName("inquiry-about");
    // checkboxesAbout.forEach((els) => els.checked && About.push(els.value));

    // var AboutString = "";
    // for (var i = 0; i < About.length; i++) {
    //   AboutString += About[i] + "-";
    // }

    // AboutString = AboutString.substring(0, AboutString.length - 1);



    

   var  ConstactString = "Phone";


    var firstname = document.getElementById("Fname").value;
    var lastname = document.getElementById("Lname").value;
    var phone = document.getElementById("Phone").value;
    var email = document.getElementById("Email").value;
    var PostCode = document.getElementById("Postal").value;
    var Note = document.getElementById("Note").value;
    var AvailabilityNote = ""
    var checkBoxTC = document.getElementById("checkbox_id")
    var conditonstc = checkBoxTC.checked
    // var radios = document.getElementsByName("contact-when");
    var contactWhen = "Morning";
    

    var validate = true;
    var firstnameerr = false
    var lastnameerr = false
    var phoneerr = false
    var emailerr = false
    var PostCodeerr = false
    // var contacterr = false
    var ContactTimeerr = true
    // var EnquiryAbouterr = false
    var ContactTypeerr = true
    var Noteerr = false
    var AvailabilityNoteerr = false
    var areyouaerr = false
    var conditonstcerr = false

    var errorField = null;

    if (firstname == "") {
      firstnameerr = false
      document.getElementById("firstnameerr").innerHTML = "Please enter first name"
      errorField = "first name";
    } else {
      firstnameerr = true
      document.getElementById("firstnameerr").innerHTML = ""
    }

    if (lastname == "") {
      lastnameerr = false
      document.getElementById("lastnameerr").innerHTML = "Please enter last name"
      errorField = errorField || "last name";
    } else {
      lastnameerr = true
      document.getElementById("lastnameerr").innerHTML = ""
    }

    if (phone == "") {
      phoneerr = false
      document.getElementById("phoneerr").innerHTML = "Please enter phone number"
      errorField = errorField || "phone number";
    } else {
      phoneerr = true
      document.getElementById("phoneerr").innerHTML = ""
    }

    if (email == "") {
      emailerr = false
      document.getElementById("emailerr").innerHTML = "Please enter email id"
      errorField = errorField || "email id";
    } else {
      emailerr = true
      document.getElementById("emailerr").innerHTML = ""
    }

    // Removing Validation from PostalCode
    // if (PostCode == "") {
    //   PostCodeerr = false
    //   document.getElementById("PostCodeerr").innerHTML = "Please enter postal code"
    //   errorField = errorField || "postal code";
    // } else {
    //   PostCodeerr = true
    //   document.getElementById("PostCodeerr").innerHTML = ""
    // }

    if (PostCode.length === 0 || PostCode.length === 4) {
      PostCodeerr = true;
      document.getElementById("PostCodeerr").innerHTML = "";
    } else {
      PostCodeerr = false;
      document.getElementById("PostCodeerr").innerHTML = "Postcode must be either empty or exactly 4 characters";
      errorField = errorField || "postcode correctly";
    }

    // if (ConstactString == "") {
    //   ContactTypeerr = false
    //   document.getElementById("ContactTypeerr").innerHTML = "Please select an option"
    //   errorField = errorField || "an option for contact type";
    // } else {
    //   ContactTypeerr = true
    //   document.getElementById("ContactTypeerr").innerHTML = ""
    // }

    // if (contactWhen == "") {
    //   ContactTimeerr = false
    //   document.getElementById("ContactTimeerr").innerHTML = "Please select an option"
    //   errorField = errorField || "an option for contact time";
    // } else {
    //   ContactTimeerr = true
    //   document.getElementById("ContactTimeerr").innerHTML = ""
    // }

    // if (AboutString == "") {
    //   EnquiryAbouterr = false
    //   document.getElementById("EnquiryAbouterr").innerHTML = "Please select an option"
    //   errorField = errorField || "an option for enquiry about";
    // } else {
    //   EnquiryAbouterr = true
    //   document.getElementById("EnquiryAbouterr").innerHTML = ""
    // }

   
    
    if (Note == "") {
      document.getElementById("Noteerr").innerHTML = "Please enter message"
      Noteerr = false
      errorField = errorField || "message";
    } else {
      document.getElementById("Noteerr").innerHTML = ""
      Noteerr = true
    }


    if (AreYouAString == "") {
      document.getElementById("areyouaerr").innerHTML = "Please select an option"
      areyouaerr = false
      errorField = errorField || "an option";
    } else {
      areyouaerr = true
      document.getElementById("areyouaerr").innerHTML = ""
    }

    // if (Contact == "") {
    //   contacterr = false
    //   document.getElementById("contacterr").innerHTML = "Please select an option"
    //   errorField = errorField || "an option for contact type";
    // } else {
    //   contacterr = true
    //   document.getElementById("contacterr").innerHTML = ""
    // }

    if (conditonstc == false) {
      conditonstcerr = false
      document.getElementById("conditonstcerr").innerHTML = "Please accept Consent"
      errorField = errorField || "accept Consent";
    } else {
      conditonstcerr = true
      document.getElementById("conditonstcerr").innerHTML = ""
    }
debugger
    if (firstnameerr == true &&
      lastnameerr == true &&
      phoneerr == true &&
      emailerr == true &&
      PostCodeerr == true &&
      // contacterr == true &&
      ContactTimeerr == true &&
      // EnquiryAbouterr == true &&
      ContactTypeerr == true &&
      areyouaerr == true && conditonstcerr == true) {

      var Data = {
        firstname: firstname,
        lastname: lastname,
        phone: phone,
        email: email,
        PostCode: PostCode,
        // EnquiryAbout: AboutString,
        ContactType: ConstactString,
        AreYou: AreYouAString,
        PropertyId: id,
        bookingtype: "visit",
        Message: Note,
        AvailableDateTimeNote: AvailabilityNote,
        ContactTime: contactWhen,
        // Contact: Contact,
        DataType: "Website",
      };
      // if (validate === true) {
      axios
        .post(
          apiBase + "/Property/AddupdateAssignProperty",
          JSON.stringify(Data)
        )
        .then((res) => {
          if (res.data.success) {
            if (res.data.data[0][0].BookInspectionID != "") {
              // document.getElementById("Success").innerHTML = "Form submitted successfully"
              document.getElementById("myForm").reset();
              //  window.location.href = "/property-thank-you-page/";
              history.push("/property-thank-you-page");
            }
          }
        })
        .catch((err) => {
          console.log("setLock err", err);
        });
      // }
    } else {
      alert(`Please enter ${errorField}`);
    }
  };

  const OpenDialog = () => {
    setOpenConfirmDialog(true);
  }

  const handleBackToList = () => {
    history.goBack();
  };

  return (
    <div>
     
     <div className="new-pr-design-wrap">
          <div className="bg-lightpink">
            <div className="container">
              <div className="wd-100">
                <div className="grid-row">
                  <div className="grid-col-6">
                    <div className="new-pr-slider-wrpa">
                    <div className="pd-img">
                  <CustomCarousel>
                    {propertydata.map((image, index) => (
                      <span key={index}>
                        <img
                          src={image.imgSrc}
                          alt="Property Image"
                          onLoad={() => console.log(`Image loaded: ${image.imgSrc}`)}
                          onError={(e) => console.error(`Error loading image: ${image.imgSrc}`, e)}
                          style={{borderRadius: "15px"}}
                        />
                      </span>
                    ))}
                  </CustomCarousel>
                </div>
                    
                    </div>
                  </div>
                  <div className="grid-col-6">
                    <div className="pr-content-new">
                      <span>Property Id:<label id="ValueCarePropertyId"></label></span>
                      <h2 id="Address"></h2>
                      <p>
                        This newly built home would offer a modern and luxurious living experience.Featuring a spacious block, this property will provide ample space for outdoor activities and a stunning facade, guaranteeing a comfortable and enjoyable living. A seamless flow of open-plan living, dining and kitchen areas that provides the perfect setting for memorable moments.
                      </p>
                      <div className="plan-option">
                        <ul>
                          <li>
                            <div className="po-capsule">
                              <img src={iconBedroom}></img>
                              <p><label id="Bedroom"></label> Bedrooms</p>
                              <span className="success-badge"><label id="AvlBedrooms"></label> Available</span>
                            </div>
                          </li>
                          <li>
                            <div className="po-capsule">
                              <img src={iconBathroom}></img>
                              <p><label id="Bathroom"></label> Bathrooms</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="book-btn">
                        <button href="javascript:;" onClick={() => scrolldiv()}>BOOK AN INSPECTION</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="property-content">
              <h3>Property Details</h3>
             
            </div>
            <div className="grid-row">
              <div className="grid-col-6">
                <div className="tick-list" dangerouslySetInnerHTML={{ __html: aboutProperty }}>
                  
                </div>
              </div>
              <div className="grid-col-6">
                <div className="fet-gr">
                  <div className="grid-row">
                    <div className="grid-col-6">
                      <div className="fg-ct">
                        <h4>Accessibility</h4>
                        <div class="po-capsule">
                          <img src={iconWheelchair}></img>
                          <p>WheelChair</p>
                        </div>
                      </div>
                    </div>
                    <div className="grid-col-6">
                      <div className="fg-ct">
                        <h4>SDA</h4>
                        <div class="po-capsule">
                          <img src={iconHighphysicalsupport}></img>
                          <p> {propertySDAData}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

       

        <div className="pd-inspection-wrap" id="sidebar">
          <div className="container">
            <div className="book-ipc-form" id="inspectionForm">
              <h3>Book an Inspection</h3>

              <form id="myForm">
                <div className="bif-form-wrap">
                  <div className="grid-row">
                    <div className="grid-col-6">
                      <div className="bif-box">
                        <label>
                          First Name<span>*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter first name"
                          name="Fname"
                          id="Fname"
                          onFocus={() => removeError("firstnameerr")}
                        />
                        <span className="error-span" id="firstnameerr"></span>
                      </div>
                    </div>
                    <div className="grid-col-6">
                      <div className="bif-box">
                        <label>
                          Last Name<span>*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter last name"
                          name="Lname"
                          id="Lname"
                          onFocus={() => removeError("lastnameerr")}
                        />
                        <span className="error-span" id="lastnameerr"></span>
                      </div>
                    </div>
                  </div>
                  <div className="grid-row">
                    <div className="grid-col-6">
                      <div className="bif-box">
                        <label>
                          Phone<span>*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter phone number"
                          name="Phone"
                          id="Phone"
                          onFocus={() => removeError("phoneerr")}
                        />
                        <span className="error-span" id="phoneerr"></span>
                      </div>
                    </div>
                    <div className="grid-col-6">
                      <div className="bif-box">
                        <label>
                          Email<span>*</span>
                        </label>
                        <input
                          type="email"
                          placeholder="Enter email"
                          name="Email"
                          id="Email"
                          onFocus={() => removeError("emailerr")}
                        />
                        <span className="error-span" id="emailerr"></span>
                      </div>
                    </div>
                  </div>
                  <div className="grid-row">
                    <div className="grid-col-6">
                      <div className="bif-box">
                        <label>
                          Postcode
                        </label>
                        <input
                          type="text"
                          placeholder="Enter postal code"
                          name="Postal"
                          id="Postal"
                          maxLength="4"
                          onFocus={() => removeError("PostCodeerr")}
                        />
                        <span className="error-span" id="PostCodeerr"></span>
                      </div>
                    </div>
                    <div className="grid-col-6"></div>
                  </div>

                  {/* <div className="grid-row">
                    <div className="grid-col-6">

                      <div className="bif-box">
                        <label>
                          How would you like us to contact you?<span>*</span>
                        </label>
                        <div className="check-value">
                          <div className="grid-row">
                            <div className="grid-col-6">
                              <label>
                                <input
                                  type="checkbox"
                                  name="constact-type"
                                  onFocus={() => removeError("ContactTypeerr")}
                                  value="Email"
                                />{" "}
                                Email
                              </label>
                              <label>
                                <input
                                  type="checkbox"
                                  name="constact-type"
                                  onFocus={() => removeError("ContactTypeerr")}
                                  value="SMS"
                                />{" "}
                                SMS
                              </label>
                              <label>
                                <input
                                  type="checkbox"
                                  name="constact-type"
                                  onFocus={() => removeError("ContactTypeerr")}
                                  value="Phone"
                                />{" "}
                                Phone
                              </label>
                              <span className="error-span" id="ContactTypeerr"></span>
                              <label for="constact-type" className="error"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="grid-col-6">
                      <div className="bif-box">
                        <label>
                          When would you like us to contact you?
                          <span>*</span>
                        </label>
                        <div className="check-value">
                          <label>
                            <input
                              type="radio"
                              name="contact-when"
                              value="Morning"
                              onFocus={() => removeError("ContactTimeerr")}
                            />{" "}
                            Morning
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="contact-when"
                              value="Afternoon"
                              onFocus={() => removeError("ContactTimeerr")}
                            />{" "}
                            Afternoon
                          </label>
                          <span className="error-span" id="ContactTimeerr"></span>
                          <label for="contact-when" className="error"></label>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="bif-box">
                    <label>
                      Available Date Time for Property Inspection<span>*</span>
                    </label>
                    <textarea id="AvailabilityNote" name="AvailabilityNote" onFocus={() => removeError("AvailabilityNoteerr")} placeholder="Write suitable date time..." ></textarea>
                    <span className="error-span" id="AvailabilityNoteerr"></span>
                  </div> */}

                  <div className="bif-box">
                    <label>
                      Leave a message<span>*</span>
                    </label>
                    <textarea id="Note" name="Note" onFocus={() => removeError("Noteerr")}></textarea>
                    <span className="error-span" id="Noteerr"></span>
                  </div>

                  <div className="bif-box">
                    <label>
                      Are you a?<span>*</span>
                    </label>
                    <div className="check-value">
                      <label>
                        <input
                          type="checkbox"
                          name="areyoua"
                          onFocus={() => removeError("areyouaerr")}
                          value="NDIS Participant"
                        />{" "}
                        NDIS Participant
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          name="areyoua"
                          onFocus={() => removeError("areyouaerr")}
                          value="Current Value Care Participant"
                        />{" "}
                        Current Value Care Participant
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          name="areyoua"
                          onFocus={() => removeError("areyouaerr")}
                          value="Carer / Family / Guardian"
                        />{" "}
                        Carer / Family / Guardian
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          name="areyoua"
                          onFocus={() => removeError("areyouaerr")}
                          value="Support Coordinator"
                        />{" "}
                        Support Coordinator
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          name="areyoua"
                          onFocus={() => removeError("areyouaerr")}
                          value="Referrer"
                        />{" "}
                        Referrer
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          name="areyoua"
                          value="Other"
                          onFocus={() => removeError("areyouaerr")}
                        />{" "}
                        Other
                      </label>
                      <span className="error-span" id="areyouaerr"></span>
                      <label for="areyoua" className="error"></label>
                    </div>
                  </div>
                  <div className="bif-box">
                    <label>
                      Consent<span>*</span>
                    </label>
                    <div className="check-value">
                      <label>
                        <input type="checkbox" onFocus={() => removeError("conditonstcerr")} name="Consent" id="checkbox_id" />I consent to
                        Value Care use, collection and disclosure of my
                        personal information, and where personal information
                        has been provided on behalf of, or in relation to
                        another person, I have their consent to do so. I
                        authorise this in accordance with Value Care 
                        <a href="https://valuecare.org.au/terms-of-use/" target="_blank" rel="noreferrer" className="read-links"> Terms and Conditions</a>
                        , <a href="https://valuecare.org.au/privacy-policy/" target="_blank" rel="noreferrer" className="read-links"> Privacy Procedure and Easy Read Privacy Collection Notice </a>
                        (as may be changed from time to time by Value Care).
                      </label>
                      <span className="error-span" id="conditonstcerr"></span>
                      <label for="Consent" className="error"></label>
                    </div>
                  </div>
                  <div className="bif-submit">
                    <button type="button" onClick={() => buttonClick()}>
                      SUBMIT
                    </button>
                  </div>
                </div>
              </form>
              <p id="Success"></p>
            </div>
          </div>
        </div>

    </div>
  );
}
export default Page3;
