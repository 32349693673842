import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiBase, } from "../utils/constant";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import UseGooglemap from "./UseGooglemap";
import Tooltip from "@material-ui/core/Tooltip";
import { Autocomplete } from "@mui/material";
import TextField from "@material-ui/core/TextField";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import "swiper/css";
import BedroomParentIcon from '@mui/icons-material/BedroomParent';
import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined';

function Page2() {
  let history = useHistory();
  const search = useLocation().search;
  const [sizeses, setSizes] = useState(0);
  const [locations, setLocation] = useState([]);
  const [AvailbleProperty, setAvailbleProperty] = useState(false);
  const [isReload, setIsReload] = useState(true);
  const [newFilterSuburb, setNewFilterSuburb] = useState("");
  const [stateErrText, setStateErrText] = useState("");
  // const id = new URLSearchParams(search).get("id");
  const statesValue = new URLSearchParams(search).get("states");
  const suburbValue = new URLSearchParams(search).get("suburb");
  console.log("statesValue", statesValue);
  console.log("suburbValue", suburbValue);

  const location = useLocation();
  const pathname = location.pathname;
  const checkingId = pathname.split("/").pop();
  const idMapping = {
    "Specialist-Disability-Accommodation": "SDA",
    "Supported-Independent-Living": "SIL",
    "Respite-Care": "Respite",
    "Medium-Term-Accommodation": "MTA",
  };
  const idMappingName = {
    "Specialist-Disability-Accommodation": "Specialist Disability Accommodation",
    "Supported-Independent-Living": "Supported Independent Living",
    "Respite-Care": "Respite Care",
    "Medium-Term-Accommodation": "Medium Term Accommodation",
  };
  const id = idMapping[checkingId] || checkingId;
  const idName = idMappingName[checkingId] || checkingId;

  const typeid = new URLSearchParams(search).get("data");
  const [list, setList] = useState([]);
  const [managedData, setManagedData] = useState("");
  const [stateList, setStateList] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [filterState, setFilterState] = useState("");
  
  const getByKey = (keyName) => {
    const jsonData = JSON.parse(localStorage.getItem("Search"));
    var keyValue = jsonData && jsonData[keyName] ? jsonData[keyName] : ''
    return keyValue
  }

  useEffect(() => {
    getStateData();
    debugger;
    const loadInitialState = async () => {
      const states = statesValue || getByKey('states')
      const suburb = suburbValue || getByKey('suburb')
      console.log("value11", states);
      console.log("value12", suburb);
      setFilterState(states);
      setNewFilterSuburb(suburb);
      showHide();
      if(states || suburb) {
        SearchProperty(states, suburb);
      } else {
        getpropertylist()
      }
    };
    loadInitialState()
  }, []);

  const getpropertylist = () => {

    const pData = { id: id };

    const urldata = new URLSearchParams(search).get("data");
    const subHeading = `Search for ${urldata} (${id}) vacancies across New South Wales, Queensland, the ACT, and Victoria.`;
    document.getElementById("heading").innerHTML = `${urldata} vacancies`;
    document.getElementById("subHeading").innerHTML = subHeading;
    document.getElementById("PropertyTypes").innerHTML = idName;

    axios
      .post(apiBase + "/Property/getPropertylistWebsite", pData)
      .then((res) => {
        if (res.data.success) {
          setLocation(null);
          const locationlist = res.data.data[0].map((item) => {
            const getSingle = item.URL.split(",");
            const imageUrl = getSingle.length > 0 ? `https://servicesapi.valuecare.org.au/${getSingle[0]}` : '';

            const addressLines = [item.AddrLine1, item.AddrLine2, item.AddrLine3].filter(Boolean).join(", ");

            const title = `${addressLines}, ${item.City}, ${item.State}, ${item.PostalCode}, Australia`;

            return {
              title: title,
              shortDes: item.BriefDescription,
              bedroom: item.TotalBedroomCount,
              bathroom: item.BathroomCount,
              availableBedroom: item.TotalAvailableBedroomCount,
              newlyAdded: item.NewlyAdded,
              imageUrl: imageUrl,
              id: `/Propertydetails/?id=${item.PropertyID}`,
              position: { lat: item.Latitude, lng: item.Longitude },
            };
          });
          setLocation(locationlist);
          setAvailbleProperty(true);
          const propertyCounting = res.data.data[0].length;
          const propertyText = propertyCounting === 0 ? '0 property found' : `${propertyCounting} properties found`;
          // document.getElementById("propertyCountText").innerHTML = propertyCounting;
          document.getElementById("propertyCountText").innerHTML = propertyText;
          setList(res.data.data[0]);

        }
      })
      .catch((err) => {
        console.log("Error fetching property data:", err);
      });
  };

  const renderProperty = (data) => {
    const hrefurl = `/Propertydetails/?id=${data.PropertyID}`;
    const propertyFeatureArray = data.PropertyFeature.split(",");
    const propertyImages = data.URL.split(",");
    const propertydata = propertyImages.map(img => ({ imgSrc: `https://servicesapi.valuecare.org.au/${img}` }));

    const htmlFeatures = propertyFeatureArray.map(feature => (
      `<li>
        <img
          src="https://wordpress-419965-2723092.cloudwaysapps.com//wp-content/themes/valueCare/images/accessible.svg"
          alt="Accessible"
        />
        <span>${feature}</span>
      </li>`
    )).join('');

    return (
      <div className="pm-list-box" key={data.PropertyID}>
        <div className="pm-image">
          <Slide>
            {propertydata.map((item, index) => (
              <span key={index}>
                <img src={item.imgSrc} alt={`Property Image ${index + 1}`} />
              </span>
            ))}
          </Slide>
        </div>

        <div className="pm-content">
          <a href={hrefurl}>
            <div>
              <div className="grid-row" >
                <div className="grid-col-2">
                  <div className="pm-category">
                    <span>{data.PropertyType}</span>
                  </div>
                </div>
                <div className="grid-col-10">
                  <div>
                    {data.NewlyAdded === 1 && (
                      <Tooltip title="Newly Added Property" id="NewlyAdded" arrow>
                        <span className="badge-new">New!</span>
                      </Tooltip>
                    )}
                    {(data.TotalAvailableBedroomCount === 1 || data.TotalAvailableBedroomCount === 2) && (
                      <Tooltip title="Few Bedroom Available to Book" id="FewLeft" arrow>
                        <span className="badge-few">Few Left!</span>
                      </Tooltip>
                    )}
                    {data.TotalAvailableBedroomCount === 0 && (
                      <Tooltip title="No Bedroom Available" id="NoAvailable" arrow>
                        <span className="badge-booked">Booked Out!</span>
                      </Tooltip>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="pm-name">
              <a href={hrefurl}>
                <h3>{`${data.AddrLine1} ${data.AddrLine2} ${data.AddrLine3}, ${data.City ? data.City + ", " : ""}${data.State ? data.State + ", " : ""}${data.PostalCode ? data.PostalCode + ", " : ""}Australia`}</h3>
              </a>
            </div>
            <div className="pm-location">
              <p>{data.BriefDescription}</p>
            </div>
            <div className="pm-avail">
              <div className="grid-row row-1">
                <div className="grid-col-7">
                  <BedroomParentIcon style={{ color: "#D84C73" }} />
                  <span>
                    <label id="Bedroom"></label> Bedrooms / <span style={{ color: "green" }}> <label id="AvlBedrooms"></label> Bedrooms Available </span>
                  </span>
                </div>
                <div className="grid-col-5">
                  <BathtubOutlinedIcon style={{ color: "#D84C73" }} />
                  <span>
                    <label id="Bathroom"></label> Bathrooms
                  </span>
                </div>
              </div>
            </div>
            <div className="pm-more">
              <a href={hrefurl}>Book an inspection</a>
            </div>
          </a>
        </div>
      </div>
    );
  };

  const displayReviewSites = () => {
    if (list.length > 0) {
      return list.map(data => renderProperty(data),);
    } else if (list.length == 0) {
      return (
        <div className="pm-content">
          <span className="NoData">No Property available for this search.</span>
        </div>
      );
    } else {
      return null;
    }
  };

  const showHide = () => {
    setIsExpanded(!isExpanded);
    let myElement = document.getElementById("hidefilter");
    let mypmOuter = document.getElementById("pmOuter");
    const hasFilterData = localStorage.getItem("Search");
    
    if (myElement.classList.contains("hidefilter") || hasFilterData) {
      myElement.classList.remove("hidefilter");
      myElement.classList.add("showfilter");
      mypmOuter.classList.add("showpmOuter");
      // setSizes(1);
      // document.getElementById("ToolHideMap").style.display ="none"
      // document.getElementById("ToolShowMap").style.display ="block"
    } else {
      myElement.classList.remove("showfilter");
      myElement.classList.add("hidefilter");
      mypmOuter.classList.remove("showpmOuter");
      // setSizes(0);
    }
  };

  const toggleClass = () => {

    let myElement = document.getElementById("toggleclass");

    if (myElement.classList.contains("showMap")) {
      myElement.classList.remove("showMap");
      myElement.classList.add("hideMap");
      setSizes(1);
    } else {
      myElement.classList.remove("hideMap");
      myElement.classList.add("showMap");
      setSizes(0);
    }
  };

  const getStateData = () => {
    const formattedData = {
      StringMapType: "STATE",
      SortType: "Alpha",
    };

    axios({
      url: apiBase + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          const stateData = res.data.data.map((item) => ({
            value: item.StringMapName,
            label: item.StringMapName,
          }));
          setStateList(stateData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getSuburbList = (input) => {
    axios
      .post(apiBase + 'Property/getDatafromState', input)
      .then((res) => {
        if (res.data.success) {
          const deliverytype = [];

          // Process each array within the data
          for (let i = 0; i < res.data.data.length - 1; i++) {
            res.data.data[i].forEach((item) => {
              const value = Object.values(item)[0];
              const label = Object.values(item)[0];
              if (value && value !== '') {
                deliverytype.push({
                  value: value,
                  label: label,
                });
              }
            });
          }
          setManagedData(deliverytype);
        }
      })
      .catch((err) => {
        console.log('Error fetching data:', err);
      });
  };

  const handleChange = (e, value, type) => {
    if (type === "states") {
      if(value) {
        setFilterState(value.label);
        var inputdata = { state: filterState, propertyType: id };
        getSuburbList(inputdata);

        let inputData = {
          states: value.label,
          suburb: newFilterSuburb,
          AvailbleProperty: AvailbleProperty ? "1" : "0",
          bedroom: AvailbleProperty ? "1" : "0",
          bathroom: "1",
          propertyType: id
        };

        const localStorageKey = "Search";
        localStorage.setItem(localStorageKey, JSON.stringify(inputData));
        history.push(`?states=${inputData.states}&suburb=${inputData.suburb}`);
      }
    } else if (type === "suburb") {
      var valueevent = e.target.value
      setNewFilterSuburb(valueevent);

      let inputData = {
        states: filterState,
        suburb: valueevent,
        AvailbleProperty: AvailbleProperty ? "1" : "0",
        bedroom: AvailbleProperty ? "1" : "0",
        bathroom: "1",
        propertyType: id
      };

      const localStorageKey = "Search";
      localStorage.setItem(localStorageKey, JSON.stringify(inputData));
      history.push(`?states=${inputData.states}&suburb=${inputData.suburb}`);
    } else if (type === "checkbox") {
      setAvailbleProperty(!AvailbleProperty);
    }
  };

  const ResetAll = () => {
    localStorage.removeItem("Search");
    const currentPath = history.location.pathname;
    const newPath = currentPath.split('?')[0];
    history.replace(newPath);
    window.location.reload();
  }

  const SearchProperty = (vState, vSuburb) => {
    debugger;
    console.log("vState", vState);
    console.log("vSuburb", vSuburb);
    var inputData = {};

    // Simplify the conditionals and use default values where applicable
    inputData.states = filterState || vState || getByKey('states');
    inputData.suburb = newFilterSuburb || vSuburb || getByKey('suburb');
    inputData.AvailbleProperty = AvailbleProperty ? "1" : "0";
    inputData.bedroom = AvailbleProperty ? "1" : "0";
    inputData.bathroom = "1";
    inputData.propertyType = id;

    // Use a constant for the localStorage key to avoid magic strings
    const localStorageKey = "Search";

    // Store inputData in localStorage
    localStorage.setItem(localStorageKey, JSON.stringify(inputData));

    axios
      .post(apiBase + "/Property/getPropertylistWebsite", inputData)
      .then((res) => {
        if (res.data.success) {
          setLocation(null);
          console.log("Res", res.data)
          console.log("ResData", res.data.data[0])
          const propertyCount = res.data.data[0].length;
          const locationlist = propertyCount ? res.data.data[0].map((item) => {
            const getSingle = item.URL.split(",");
            const imageUrl = getSingle.length > 0 ? `https://servicesapi.valuecare.org.au/${getSingle[0]}` : '';

            const addressLines = [item.AddrLine1, item.AddrLine2, item.AddrLine3].filter(Boolean).join(", ");
            const title = `${addressLines}, ${item.City}, ${item.State}, ${item.PostalCode}, Australia`;

            return {
              title: title,
              shortDes: item.BriefDescription,
              bedroom: item.TotalBedroomCount,
              bathroom: item.BathroomCount,
              availableBedroom: item.TotalAvailableBedroomCount,
              newlyAdded: item.NewlyAdded,
              imageUrl: imageUrl,
              id: `/Propertydetails/?id=${item.PropertyID}`,
              position: { lat: item.Latitude, lng: item.Longitude },
            };
          }) : [];
          setIsReload(true);
          setLocation(locationlist);
          // setAvailbleProperty(propertyCount > 0);
          setList(res.data.data[0]);
          console.log("propertyCount", propertyCount);
          let propertyText = propertyCount === 0 ? '0 property found' : `${propertyCount} ${propertyCount === 1 ? 'property' : 'properties'} found`;
          document.getElementById("propertyCountText").innerHTML = propertyText;
        }
      })
      .catch((err) => {
        console.log("Error fetching property data:", err);
      });
  };


  return (
    <div>
      <div class="property-list-wrap" style={{ display: "none" }}>
        <div class="pl-header">
          <div class="container">
            <div class="pl-header-inner">
              <h2 id="heading"></h2>
              <span class="devider"></span>
              <p id="subHeading"></p>
            </div>
          </div>
        </div>

        <div class="filter-wrap">
          <div class="container">
            <div class="filter-inner"></div>

            <div class="pl-content-wrap" id="mapshow">
              <div class="container" id="manual">
                <UseGooglemap locationlist={locations} />
              </div>

              <div className="pl-content-wrap" id="propertylistadd">
                <div className="container">
                  <div className="pl-content-inner">
                    <div classNameName="pl-list-outer" id=""></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="property-map-wrap showMap" id="toggleclass">
        <div className="pm-row">
          <div className="pm-list-wrap">
            <div className="pm-list">
              <div className="pm-title">
                <h3 id="PropertyTypes"></h3>
                <div className="pmt-right">
                  <p>
                    <span id="propertyCountText"></span>
                  </p>

                  {sizeses == 0 ? (
                    <Tooltip title="Hide Map" id="ToolHideMap" arrow>
                      <a
                        onClick={() => toggleClass()}
                        className="map-show-hide"
                      >
                        <ArrowForwardIosIcon></ArrowForwardIosIcon>
                      </a>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Show Map" id="ToolShowMap" arrow>
                      <a
                        onClick={() => toggleClass()}
                        className="map-show-hide"
                      >
                        <ArrowForwardIosIcon></ArrowForwardIosIcon>
                      </a>
                    </Tooltip>
                  )}
                </div>
              </div>
              <div className="pm-filter">
                <div className="pmf-title" onClick={() => showHide()}>
                  <h3>Filter</h3>
                  <a href="#">
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </a>
                </div>
                <div className="pmf-form hidefilter" id="hidefilter">
                  <div class="grid-row">

                    <div className="grid-col-5">
                      <div className="filter-input state-list">

                        <Autocomplete
                          className="state-filter-autocomplete"
                          options={stateList}
                          value={{ label: filterState }}
                          onChange={(event, value) => handleChange(event, value, 'states')}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="state-filter-input"
                              label="State"
                              fullWidth
                              SelectProps={{
                                native: true,
                              }}
                            />
                          )}
                        />

                      </div>
                    </div>

                    <div className="grid-col-5">
                      <div className="filter-input" id="suburb-search">
                        <input
                          type="text"
                          id="suburb"
                          value={newFilterSuburb}
                          placeholder="Search Suburb/Post Code"
                          onChange={(event) => handleChange(event, "", "suburb")}
                        />

                        <span
                          id="cname"
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          {stateErrText}
                        </span>
                      </div>
                    </div>
                    <div className="grid-col-2">
                      <div className="filter-submit">
                        <button
                          type="button"
                          className="search-btn"
                          onClick={() => SearchProperty()}
                        >
                          <SearchIcon></SearchIcon>
                        </button>
                        <button type="button" onClick={() => ResetAll()} className="reset-btn">
                          <RestartAltIcon></RestartAltIcon>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="filter-check-box">
                    <label for="myCheckbox">
                      <input
                        type="checkbox"
                        id="myCheckbox"
                        value={AvailbleProperty ? "checked" : "unchecked"}
                        checked={AvailbleProperty}
                        onChange={(event) => handleChange(event, "", "checkbox")}
                      />
                      <span>Show only available bedrooms</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="pm-list-outer" id="pmOuter">
                {displayReviewSites()}
              </div>
            </div>
          </div>
          <div className="pm-map">
            <UseGooglemap locationlist={locations} />

            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d353300.1363014915!2d150.96972040084566!3d-33.83421307387104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1scare%20homes%20near%20Australia!5e0!3m2!1sen!2sin!4v1697200469828!5m2!1sen!2sin" width="100%" height="550" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Page2;
