import React, { useEffect, useRef } from "react";
import { useGoogleMaps } from "react-hook-google-maps";

const uluru = { lat: -25.274399, lng: 133.775131 };

const UseGooglemap = React.memo(function Map(props) {
  const { ref, map, google } = useGoogleMaps(
    "AIzaSyBwg_LyNB9AsizTdmTEX2gp-TydKCxFv84",
    {
      zoom: 4,
      center: uluru,
    }
  );

  const markersRef = useRef([]);

  useEffect(() => {
    if (map && props.locationlist.length > 0) {
      // Clear existing markers
      markersRef.current.forEach(marker => {
        marker.setMap(null);
      });
      markersRef.current = [];

      props.locationlist.forEach(markerData => {
        const marker = new google.maps.Marker({
          position: markerData.position,
          map,
          title: markerData.title
        });

        const infowindow = new google.maps.InfoWindow({
          content: '<div class="map-callout"> <div class="pm-list-box"> <div class="pm-image"> <span> <img src="'+markerData.imageUrl+'" alt="Property Image"></img> </span> </div><div class="pm-content"> <div class="pm-category"> <span>INDEOENDENT LIVING</span> </div><div class="pm-name"> <h3>'+markerData.title+'</h3> </div><div class="pm-location"> <p>'+markerData.shortDes+'</p></div><div class="pm-avail"> <span><b>'+markerData.bedroom+'</b> Bedreooms</span> <span><b>'+markerData.bathroom+'</b> Bathrooms</span> </div><div class="pm-more"><a href="'+markerData.id+'">Book an inspection</a></div></div></div></div>',
          ariaLabel: "Marker Info",
        });

        marker.addListener("click", () => {
          infowindow.open({
            anchor: marker,
            map,
          });
        });

        markersRef.current.push(marker);
      });

      const bounds = new google.maps.LatLngBounds();
      props.locationlist.forEach(markerData => {
        bounds.extend(markerData.position);
      });
      map.fitBounds(bounds);
      const minZoom = 2;
      if (map.getZoom() < minZoom) {
        map.setZoom(minZoom);
      }
    }
  }, [map, props.locationlist, google]);

  return (
    <div className="map-div" ref={ref} />
  );
});

export default UseGooglemap;
